import React from 'react';
import './uniswap.css'; // Ensure you have a CSS file for custom styles
import coin from '../../assets/bbld/coin.gif';
import uni from '../../assets/uniswap-uni-logo.png';
import { Link } from 'react-router-dom';

const Uniswap = () => {
  return (
    <div className="uniswap-full-width-card">
      <div className="uniswap-pretty-card">
        <div className="uniswap-coin-container">
          <img
            src={coin}
            alt="BBLD"
            style={{
              maxWidth: '30vh', // Adjusted size
              height: 'auto',
              objectFit: 'contain',
            }}
          />
        </div>
        <h2 className="uniswap-header">
          <img src={uni} alt="Uniswap Logo" className="uniswap-header-logo" />
          Uniswap $BBLD Liquidity Pool
          <img src={uni} alt="Uniswap Logo" className="uniswap-header-logo" />
        </h2>
        <p>
          We have set up a liquidity pool for BBLD tokens on Uniswap, a decentralized exchange. A liquidity pool allows users to trade BBLD tokens while ensuring liquidity is available in the pool. By adding liquidity, you contribute to the stability of the token's trading market.
        </p>
        <p>
          <strong>What is a liquidity pool?</strong> A liquidity pool is a collection of funds locked in a smart contract that provides liquidity for decentralized exchanges. Users who add their tokens to the pool earn a share of the transaction fees generated by trades within the pool.
        </p>
        <p>
          <strong>How to use the pool:</strong> You can add liquidity to the BBLD pool by depositing your BBLD tokens and the corresponding amount of ETH into the pool. In return, you will receive liquidity provider (LP) tokens, which represent your share in the pool.
        </p>
        <p>
          <strong>Benefits of adding liquidity:</strong> By adding liquidity, you help maintain a healthy trading environment for BBLD. You also earn a portion of the transaction fees from trades that occur in the pool, which can be a lucrative incentive.
        </p>
        <p>
          <strong>Encouragement:</strong> We encourage you to contribute to the BBLD liquidity pool on Uniswap to support the growth and stability of the BBLD token. Your participation is invaluable to our community and the broader DeFi ecosystem.
        </p>
        <div className="uniswap-button-container">
          <a
            href="https://app.uniswap.org/explore/tokens/ethereum/0xdcbadc585a2b0216c2fe01482aff29b37ffbc119"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="uniswap-button">View on Uniswap</button>
          </a>
        </div>
        <div className="uniswap-iframe-container">
          <iframe
            src="https://app.uniswap.org/explore/tokens/ethereum/0xdcbadc585a2b0216c2fe01482aff29b37ffbc119"
            style={{
              width: '95%', // Adjust the width to 95%
              height: '500px', // Adjust the height as needed
              border: 'none',
              margin: '0 auto', // Center the iframe
              display: 'block'
            }}
            title="Uniswap BBLD Pool"
          ></iframe>
        </div>
        <div className="uniswap-button-container">
          <Link to="/">
            <button className="uniswap-button">Back</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Uniswap;
